@import "../../../../_variables";

.admin-dashboard-wrapper {
  color: white;
  max-width: 1400px;
  margin: auto;
  .title {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    text-decoration: underline;
    color: $secondaryColor;
  }
  .new-fundraiser-button-wrapper {
    @include centerElementByFlex(row, flex-end, flex-end);
    margin: 10px 0px;
  }

  .stats-wrapper {
    @include centerElementByFlex(row, flex-start, unset);
    margin: 40px 0px 20px 0px;
    .stat-title {
      color: $secondaryColor;
      text-decoration: underline;
      font-size: 20px;
      font-weight: 700;
    }
    .stat {
      color: $secondaryColor;
      margin: 10px 50px 10px 0px;
      @include centerElementByFlex(row, flex-start, unset);
      span {
        color: white;
        // max-width: 180px;
        display: block;
        margin-left: 10px;
        margin-right: -6px;
      }
    }
    .new-fundraising-button-wrapper {
    }
  }

  .stat-one-wrapper {
    max-width: 100%;
  }
  .stats-wrapper-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: auto;
    padding-top: 50px;
    .stat-title {
      color: $secondaryColor;
      text-decoration: underline;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 20px; /* Add margin bottom to push stats down */
    }
    .stat {
      border: solid 1px $secondaryColor;
      border-radius: 10px;
      padding: 20px;
      color: $secondaryColor;
      margin: 10px 0px; /* Adjust margin */
      display: flex;
      font-size: 24px;
      align-items: center;
      span {
        color: white;
        display: block;
        margin-left: 10px;
        margin-right: -6px;
        font-size: 20px;
        overflow: auto;
      }
    }
    .new-fundraising-button-wrapper {
    }
  }

  .admin-sub-section-wrapper {
    margin: 50px 0px;
    .admin-sub-title {
      text-align: center;
      color: $secondaryColor;
      text-decoration: underline;
      font-weight: 600;
      font-size: 30px;
      margin-bottom: 20px;
    }
    .admin-invite-button-wrapper {
      @include centerElementByFlex(row, flex-start, center);
      margin: 10px 0px;
    }
  }
}

.admin-fundraiser-wrapper {
  .admin-sub-title {
    text-align: center;
    color: $secondaryColor;
    text-decoration: underline;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 20px;
  }
  .admin-fundraiser-list-wrapper {
    max-width: 100vw;
    padding: 0px 10px;
    overflow: auto;
    .admin-invite-button-wrapper {
      margin: 15px 0px;
      display: flex;
      justify-content: space-between;
      .fundraiser-button {
        font-size: small;
        max-width: 40vw;
      }
    }
  }
}

.admin-permission-wrapper {
  width: 400px;
  .middle-section {
    margin: 20px 0px;
  }
  .privilege-wrapper {
    margin: 5px 0px;
    .title {
      font-weight: bold;
      color: $secondaryColor;
      font-size: 16px;
      margin: 5px 0px;
    }
    .privilege-list {
      .privilege {
        font-size: 12px;
        display: inline;
      }
      .privilege-child {
        @include centerElementByFlex(column, flex-start, flex-start);
        margin-left: 15px;
      }
    }
  }
  .admin-permission-button-wrapper {
    .button {
      width: 100%;
    }
  }
}

.dashboard-delete-module-wrapper {
  .para {
    margin: 50px 0px;
    font-size: 15px;
  }
  .dashboard-delete-buttons-wrapper {
    @include centerElementByFlex(row, center, center);

    .button-wrapper {
      @include centerElementByFlex(row, space-evenly, center);
      width: 100%;
      .button {
        width: 50%;
      }
      .button:last-child {
        margin-left: 10px;
      }
    }
  }
}

.admin-approved-section-wrapper {
  margin: 50px 0px;
  .admin-sub-title {
    text-align: center;
    color: $secondaryColor;
    text-decoration: underline;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 20px;
  }
  .admin-invite-button-wrapper {
    @include centerElementByFlex(row, flex-start, center);
    margin: 10px 0px;
  }
}

.admin-all-groups-wrapper {
  .title {
    text-align: center;
    color: $secondaryColor;
    text-decoration: underline;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 20px;
  }
  .create-group-button {
    @include centerElementByFlex(row, flex-end, center);
    margin: 10px 0px;
  }
  .no-groups-wrapper {
    margin: 20px 0px;
    color: $secondaryColor;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
  }
}

.admin-group-wrapper {
  margin: 50px 0px;
  .group-header-wrapper {
    @include centerElementByFlex(row, center, center);
    margin: 10px 0px;
    .group-name {
      text-align: center;
      margin: 10px 0px;
      font-size: 20px;
      font-weight: 600;
      margin-right: 10px;
    }
    .icon-wrapper {
      border: 1px solid $secondaryColor;
      border-radius: 50%;
      margin: 0px 5px;
      @include centerElementByFlex(row, center, center);
      cursor: pointer;
      .icon {
        font-size: 20px;
        margin: 10px;
        color: $secondaryColor;
      }
    }
  }
  .participant-name {
    @include centerElementByFlex(row, center, center);
    margin: 10px 0px;
    .name {
      text-align: center;
      margin: 10px 0px;
      font-size: 20px;
      font-weight: 600;
      margin-right: 10px;
      b {
        font-weight: bold;
        color: $secondaryColor;
      }
    }
    .icon-wrapper {
      border: 1px solid $secondaryColor;
      border-radius: 50%;
      margin: 0px 5px;
      @include centerElementByFlex(row, center, center);
      cursor: pointer;
      .icon {
        font-size: 20px;
        margin: 10px;
        color: $secondaryColor;
      }
    }
  }

  .invite-team-member {
    .invite-link {
      span {
        color: $secondaryColor;
        margin: 0px 10px;
      }
    }

    @include centerElementByFlex(row, none, center);
    margin: 15px 0px;
    .icon-wrapper {
      border: 1px solid $secondaryColor;
      border-radius: 50%;
      margin: 0px 5px;
      @include centerElementByFlex(row, center, center);
      cursor: pointer;

      .icon {
        font-size: 20px;
        margin: 10px;
        color: $secondaryColor;
      }
    }
    .assign_participants {
      margin-left: auto;
    }
  }
}

.admin-group-modal-inner-wrapper {
  .para {
    font-size: 12px;
    margin: 15px 0px;
  }
  .warning {
    font-size: 14px;
    color: gray;
  }
  .submit-button {
    margin: 10px 0px;
    .submit {
      width: 100%;
    }
  }
}

.invite-team-member-inner-wrapper {
  .no-participant-message {
    margin: 40px 0px;
  }
  .input-wrapper {
    margin: 20px 0px;
  }
  .submit-button {
    .button {
      width: 100%;
    }
  }
}

.fundraiser-modal-wrapper {
  .no-fund-message {
    margin: 10px 0px;
  }
  .button-wrapper {
    margin: 20px 0px;
    .button {
      width: 100%;
    }
  }
}

.admin-sponser-wrapper {
  margin: 20px 0px;
  .title {
    color: $secondaryColor;
    text-decoration: underline;
    text-align: center;
    font-size: 30px;
  }
  .sponsers-wrapper {
    width: 100%;
    margin: 40px 0px;

    .grid-container {
      @include centerElementByFlex(row, center, center);
      .grid-wrapper {
        @include centerElementByFlex(row, center, center);
      }
      .image-wrapper {
        margin: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.admin-donor-wrapper {
  .title {
    color: $secondaryColor;
    text-decoration: underline;
    text-align: center;
    font-size: 30px;
  }
  .paper-wrapper {
    max-width: 800px;
    margin: 20px auto;
    background-color: #878787;
    .paper-title {
      font-size: 25px;
      padding-top: 20px;
      font-weight: bold;
      text-align: center;
      color: black;
    }
    .donors-wrapper {
      padding: 10px 50px;
      .donor {
        border-bottom: 1px solid black;
        margin: 10px 0px;
        padding: 10px 0px;
      }
    }
  }
}

.invitaion-link-warpper {
  @include centerElementByFlex(column, center, center);
  .secondary-admin-link {
    color: $secondaryColor;
  }
  .icon-wrapper {
    border: 1px solid $secondaryColor;
    border-radius: 50%;
    margin: 0px 5px;
    @include centerElementByFlex(row, center, center);
    cursor: pointer;
    margin-top: 30px;
    .icon {
      font-size: 20px;
      margin: 10px;
      color: $secondaryColor;
    }
  }
}

.secondary-admin-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;

  .customBtn {
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
  }
}

.verification-in-progress-text {
  text-align: center;
  margin-top: 10px;
  font-size: large;
  color: orange;
}

@media screen and (max-width: 1000px) {
  .admin-dashboard-wrapper {
    color: white;
    .stats-wrapper {
      @include centerElementByFlex(column, center, center);
      margin: 40px 0px 20px 0px;
      .stat-title {
        color: $secondaryColor;
        text-decoration: underline;
        font-size: 20px;
        font-weight: 700;
      }
      .stat {
        color: $secondaryColor;
        margin: 10px 10px;
        @include centerElementByFlex(row, flex-start, unset);
        span {
          color: white;
          display: block;
          margin-left: 10px;
          margin-right: -6px;
        }
      }
    }
    .stats-wrapper-centered {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin: auto;
      padding-top: 50px;
      .stat-title {
        color: $secondaryColor;
        text-decoration: underline;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 20px; /* Add margin bottom to push stats down */
      }
      .stat {
        color: $secondaryColor;
        margin: 10px 0; /* Adjust margin */
        display: flex;
        align-items: center;
        font-size: 24px;
        span {
          color: white;
          display: block;
          margin-left: 10px;
          margin-right: -6px;
        }
      }
      .new-fundraising-button-wrapper {
      }
    }
    .admin-sub-section-wrapper {
      margin: 50px 0px;
      .admin-sub-title {
        text-align: center;
        color: $secondaryColor;
        text-decoration: underline;
        font-weight: 600;
        font-size: 30px;
        margin-bottom: 20px;
      }
      .admin-invite-button-wrapper {
        @include centerElementByFlex(row, flex-start, center);
        margin: 10px 0px;
      }
    }
  }
}

.line-with-text {
  text-align: center;
  border-bottom: 1px solid $secondaryColor;
  line-height: 0.1em;
  margin: 20px 0;
}

.line-with-text span {
  padding: 0 10px;
  background-color: black;
  color: $secondaryColor;
  font-weight: 600;
}

.fundraiser-info-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-weight: 700;
    font-size: 24px;
    color: $secondaryColor;
    text-decoration: underline;
    margin-bottom: 20px;
  }
  .direct-deposit {
    @media (min-width: 800px) {
      width: 500px;
    }
  }

  .custom-input-wrapper {
    margin-top: 10px;
  }
  .custom-date-wrapper {
    margin-top: 10px;
  }
  .custom-button-wrapper {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 40px;
    user-select: none;
  }
  .button-group {
    display: flex;
    justify-content: center;
    .submit-button {
      width: 80%;
      max-width: 250px;
    }
  }
}

.info-modal-wrapper {
  .condition-list {
    color: white;
    margin: 20px 0px;
    display: flex;
    .para {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      display: inline;
      .link {
        color: $secondaryColor;
        text-decoration: underline;
        font-weight: 500;
        margin-left: 5px;
      }
    }
  }
  .customDate {
    min-width: 230px;
  }
}

.similar-fundraisers-dialog {
  .dialog-title {
    width: 550px;
  }
}

.similar-fundraisers-modal {
  padding-top: 20px;
  width: 100%;
  .button-group {
    padding-top: 20px;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    justify-content: space-between;
    .custom-button-wrapper {
      padding-left: 30px;
      padding-right: 30px;
      user-select: none;
    }
  }
}

@media screen and (max-width: 650px) {
  .similar-fundraisers-modal {
    .button-group {
      padding-left: 0px;
      padding-right: 0px;
      justify-content: space-between;
      gap: 5px;
    }
    .custom-button-wrapper {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

.groups-modal {
  max-width: 500px;
  .para {
    width: 80%;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
  }
  .heading {
    font-size: 28px;
    font-weight: 600;
    color: $secondaryColor;
    margin-top: 20px;
  }
  .group-internal-wrapper {
    display: flex;
    justify-content: space-between;
    .group-form {
      margin-right: 10px;
      width: 80%;
      .error {
        font-size: small;
        color: red;
      }
      .custom-input {
        margin-top: 10px;
      }
      .info {
        font-size: 12px;
      }
    }
    .group-delete-button {
      height: fit-content;
      align-self: center;
    }
  }
  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    .custom-button {
      margin-top: 10px;
      width: 70%;
      font-weight: 800;
    }
  }
}

.goal-modal {
  width: 350px;
  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    .custom-button {
      width: 80%;
      padding: 10px;
      margin-top: 10px;
    }
  }
  .group-goal-wrapper {
    .heading {
      font-size: 28px;
      font-weight: 600;
      color: $secondaryColor;
      margin-top: 20px;
    }
    .group-name {
      margin-bottom: 15px;
      font-size: 14px;
      color: rgb(200, 200, 200);
    }
  }
  .info {
    font-weight: 300;
    font-size: 14px;
  }
  .spacing {
    margin-top: 5px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 650px) {
  .goal-modal {
    width: 250px;
  }
}

.cause-modal {
  width: 350px;

  @media (min-width: 600px) {
    width: 500px;
  }

  .combined-cause {
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    .custom-button {
      width: 80%;
      max-width: 250px;
      padding: 10px;
      margin-top: 10px;
    }
  }
  .group-cause-wrapper {
    .heading {
      font-size: 28px;
      font-weight: 600;
      color: $secondaryColor;
      margin-top: 20px;
    }
    .group-name {
      margin-bottom: 15px;
      font-size: 14px;
      color: rgb(200, 200, 200);
    }
  }
}

@media screen and (max-width: 650px) {
  .cause-modal {
    width: 250px;
  }
}

.stripe-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  .title {
    font-weight: 700;
    font-size: 24px;
    color: $secondaryColor;
    text-decoration: underline;
    margin-bottom: 10px;
  }
  .info {
    text-align: start;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
  }
  .more-info {
    text-align: center;
    font-size: 14px;
    margin-bottom: 20px;
    a {
      color: $secondaryColor;
    }
  }
  .no-stripe-text {
    font-size: small;
    text-align: center;
    margin-bottom: 10px;
  }
  .stripe-button-group {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 20px;
    .stripe-login {
      width: 40%;
      max-width: 200px;
      margin-right: 10px;
    }
    .stripe-create-account {
      width: 60%;
      max-width: 200px;
    }
  }
  .skip-button {
    width: 80%;
    max-width: 300px;
  }
  .submit-button {
    width: 80%;
    max-width: 300px;
    margin-bottom: 5px;
  }
  .footer-info {
    width: 80%;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    max-width: 300px;
    text-align: center;
    margin-top: 5px;
  }
  .custom-select-wrapper {
    .label {
      text-align: center;
      font-size: 16px;
      font-weight: bolder;
    }
  }
  .manage-accounts {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    a {
      color: $secondaryColor;
      font-size: 14px;
    }
    p {
      color: $secondaryColor;
      cursor: pointer;
      font-size: 10px;
      margin-left: 10px;
      align-self: center;
      border: solid 1px;
      border-radius: 4px;
      padding: 2px;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
  .centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.fundraiser-dashboard-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* For medium screens */
  @media screen and (max-width: 1100px) {
    .section {
      min-width: calc(100% / 2);
    }
  }

  /* For small screens */
  @media screen and (max-width: 650px) {
    .section {
      min-width: 100%;
    }
  }

  .title {
    color: $secondaryColor;
    font-size: 30px;
    text-decoration: underline;
    text-align: center;
    font-weight: 500;
  }

  .title2 {
    color: $secondaryColor;
    font-size: 24px;
    text-decoration: underline;
    text-align: center;
    font-weight: 500;
  }

  .sections {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;

    .section {
      flex: 1;
      color: $secondaryColor;
      box-sizing: border-box;
      padding: 20px;
      max-width: 400px;

      .info-entity-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        user-select: none;
        .info-entity {
          display: flex;
          justify-content: flex-start;
          .info-type {
            font-weight: 500;
            margin-right: 2px;
          }
          .info-value {
            color: white;
            margin-left: 8px;
            font-weight: 300;
            overflow-x: auto;
          }
        }
        .change-button {
          display: flex;
          align-items: center;
          font-size: 12px;
          text-decoration: underline;
          cursor: pointer;
          margin-left: 5px;
        }
        .change-button:hover {
          color: #fafa00;
        }
      }

      .section-title {
        font-size: 18px;
        text-decoration: underline;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .button-group {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        user-select: none;
        .edit-button {
          cursor: pointer;
          text-decoration: underline;
          font-size: 14px;
          margin-right: 50px;
        }
        .edit-button:hover {
          color: #fafa00;
        }
        .delete-button {
          color: red;
          cursor: pointer;
          text-decoration: underline;
          font-size: 14px;
          margin-right: 50px;
        }
        .delete-button:hover {
          color: #f98e13;
        }
      }
    }
  }

  .para {
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }

  .table {
    max-width: 100vw;
    padding: 0px 10px;
    overflow: auto;
  }

  .wide {
    width: 100%;
  }

  .button-box-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;

    .button-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 300px;
      margin: 0px 10px;
      margin-top: 20px;
      .custom-button {
        width: 100%;
        margin-top: 10px;
      }
      .no-margin {
        margin: 0px !important;
      }
      .heading {
        color: $secondaryColor;
        font-weight: 500;
        font-size: 20px;
        padding-top: 5px;
        padding-bottom: 1px;
      }
      .para {
        margin-top: 5px;
      }
    }
    // @media screen and (max-width: 750px) {
    //   flex-direction: column;
    //   align-items: center;
    //   .custom-button {
    //     margin-top: 15px;
    //   }
    // }
  }

  .button-box-group-left {
    display: flex;
    justify-content: flex-start;
    margin: 20px 10px;
  }

  .small-button-box-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .custom-button {
      max-width: 145px;
    }
  }

  .administrators {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-bottom: 10px;
    }
    .table {
      width: 100vw;
      max-width: 700px;
    }
  }

  .participants {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      color: $secondaryColor;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 500;
    }
    .table {
      width: 100vw;
      max-width: 700px;
    }
  }

  .groups {
    .title {
      margin-bottom: 10px;
    }
  }

  .group {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    border: solid 2px black;
    padding-top: 20px;

    .group-info {
      max-width: 400px;
      min-width: 200px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    .group-info-horizontal {
      display: flex;
      width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;
      align-items: start;
      min-width: 200px;
      margin-top: 10px;
      margin-bottom: 15px;
      gap: 15px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .table {
      width: 100%;
    }
    .edit-icon {
      margin-left: 5px;
      color: orangered;
      cursor: pointer;
    }
    .edit-icon:hover {
      color: red;
    }
    .info-entity-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      max-width: 350px;
      color: $secondaryColor;
      user-select: none;
      .info-entity {
        display: flex;
        justify-content: flex-start;
        .info-type {
          font-size: 18px;
          font-weight: 600;
          margin-right: 2px;
          min-width: 70px;
        }
        .info-value {
          color: white;
          margin-left: 8px;
          font-weight: 300;
          overflow-x: auto;
        }
      }
      .change-button {
        display: flex;
        align-items: center;
        margin-left: 10px;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
      }
      .change-button:hover {
        color: #fafa00;
      }
    }
    .heading {
      color: $secondaryColor;
      font-size: 24px;
      text-align: center;
      font-weight: 300;
      display: flex;
      align-items: center;
      .type {
        font-weight: 600;
        margin-right: 5px;
      }
      .edit-button {
        display: flex;
        justify-content: center;
        cursor: pointer;
        margin-left: 10px;
      }
      .delete-button {
        display: flex;
        justify-content: center;
        cursor: pointer;
        margin-left: 5px;
        color: red;
      }
    }
    .donation-link-wrapper {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .group:hover {
    background-color: #171717;
    border-radius: 10px;
    border: solid 2px $secondaryColor;
  }

  .games {
    .table {
      width: 100vw;
      max-width: 700px;
    }
    .title {
      margin-top: 50px;
      margin-bottom: 10px;
    }
    .info {
      color: white;
      font-size: 14px;
      text-align: center;
      font-weight: 300;
    }
    .games-buttons {
      margin: 10px;
      .custom-button {
        margin-right: 10px;
      }
    }
  }
}

.participant-fundraiser-dashboard-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* For medium screens */
  @media screen and (max-width: 1100px) {
    .section {
      min-width: calc(100% / 2);
    }
  }

  /* For small screens */
  @media screen and (max-width: 650px) {
    .section {
      min-width: 100%;
    }
  }

  .title {
    color: $secondaryColor;
    font-size: 30px;
    text-decoration: underline;
    text-align: center;
    font-weight: 500;
  }

  .sections {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;

    .section {
      flex: 1;
      color: $secondaryColor;
      box-sizing: border-box;
      padding: 20px;
      max-width: 400px;

      .info-entity-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        user-select: none;
        .info-entity {
          display: flex;
          justify-content: flex-start;
          .info-type {
            font-weight: 500;
            margin-right: 2px;
          }
          .info-value {
            color: white;
            margin-left: 8px;
            font-weight: 300;
            word-break: break-all;
            overflow-x: auto;
          }
        }
        .change-button {
          display: flex;
          align-items: center;
          font-size: 12px;
          text-decoration: underline;
          cursor: pointer;
          margin-left: 5px;
        }
        .change-button:hover {
          color: #fafa00;
        }
      }

      .section-title {
        font-size: 18px;
        text-decoration: underline;
        font-weight: 600;
        margin-bottom: 10px;
      }

      .button-group {
        display: flex;
        margin-top: 20px;
        margin-bottom: 20px;
        .edit-button {
          cursor: pointer;
          text-decoration: underline;
          font-size: 14px;
          margin-right: 50px;
        }
        .edit-button:hover {
          color: #fafa00;
        }
        .delete-button {
          color: red;
          cursor: pointer;
          text-decoration: underline;
          font-size: 14px;
          margin-right: 50px;
        }
        .delete-button:hover {
          color: #f98e13;
        }
      }
    }
  }

  .para {
    color: white;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }

  .table {
    max-width: 100vw;
    min-width: 50vw;
    padding: 0px 10px;
    overflow: auto;
  }

  .contacts {
    margin-top: 50px;
    .table {
      margin-top: 30px;
    }
  }

  .team-members {
    margin-top: 50px;
    .table {
      margin-top: 30px;
    }
  }

  .button-box-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .button-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 800px;
      min-width: 200px;
      margin: 0px 10px;
      .custom-button {
        width: 100%;
        margin-top: 10px;
      }
      .para {
        margin-top: 5px;
      }
    }
  }

  .button-box-group-left {
    display: flex;
    justify-content: flex-start;
    margin: 20px 10px;
  }

  .small-button-box-group {
    display: flex;
    justify-content: space-between;
    width: 50vw;
    min-width: 200px;
    max-width: 300px;
    .custom-button {
      padding: 5px;
      max-width: 145px;
      height: 50px;
    }
    .middle-button {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .administrators {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin-bottom: 10px;
    }
    .table {
      width: 100vw;
      max-width: 620px;
    }
  }

  .participants {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .heading {
      color: $secondaryColor;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 500;
    }
    .table {
      width: 100vw;
      max-width: 620px;
    }
  }

  .games-info {
    color: white;
    margin: 10px;
  }
  .games-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
  }
}

.invite-participant-modal {
  .input {
    width: 450px;
    max-width: 100%;
    margin-bottom: 5px;
  }
  .submit-button {
    margin-top: 20px;
    width: 100%;
  }
}

.assign-participant-modal {
  .table {
    margin-bottom: 10px;
  }
  .custom-btn-wrapper {
    display: flex;
    justify-content: center;
  }
  .custom-btn {
    width: 40%;
    min-width: 100px;
  }
}

.link-modal {
  .logo {
    width: 200px;
    border-radius: 15px;
    border: solid $secondaryColor 3px;
  }
  .message {
    font-weight: 600;
  }
  .invitation-link {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    word-break: break-all;
    color: white;
  }
  .button-wrapper {
    display: flex;
    gap: 6px;
    width: 100%;
    justify-content: flex-end;
  }
  .info {
    color: $secondaryColor;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .link-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    .indicator-change-button {
      display: flex;
      align-items: center;
      font-size: 10px;
      height: 20px;
      cursor: pointer;
      margin-left: 5px;
      padding: 2px 4px;
      border-radius: 4px;
      border: 1px solid transparent;
    }

    .indicator-change-button.activate {
      color: green;
      border-color: green;
      background-color: rgba(0, 255, 0, 0.1);
    }

    .indicator-change-button.deactivate {
      color: red;
      border-color: red;
      background-color: rgba(255, 0, 0, 0.1);
    }

    .indicator-change-button.loading {
      color: gray;
      border-color: gray;
      background-color: rgba(134, 134, 134, 0.1);
      cursor: default;
    }
  }
}

@media screen and (max-width: 600px) {
  .link-modal {
    .logo {
      width: 120px;
    }
    .message {
      font-size: 14px;
      margin-top: 10px;
    }
    .invitation-link {
      font-size: 12px;
    }
  }
}

.confirmation-modal {
  .info {
    margin-bottom: 10px;
  }
  .custom-button {
    padding: 15px 20px;
    margin-right: 10px;
  }
  .warning-button {
    padding: 15px 20px;
    margin-right: 10px;
    color: red;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .spinner-wrapper {
    width: 100px;
  }
}

.contact-modal {
  max-width: 350px;
  .input {
    width: 450px;
    max-width: 100%;
    margin-bottom: 5px;
  }
  .submit-button {
    margin-top: 20px;
    width: 100%;
  }
}

.link-stripe-modal {
  .info {
    font-size: 18px;
    margin-top: 5px;
    a {
      color: $secondaryColor;
    }
  }
  .note {
    margin-top: 10px;
    font-size: 14px;
    color: $secondaryColor;
  }
  .custom-button {
    margin-top: 10px;
  }
  a {
    color: $secondaryColor;
    font-size: small;
  }
}

.share-tokens-modal {
  .info {
    .highlight {
      font-weight: bold;
      color: $secondaryColor;
      font-size: 14px;
    }
    color: lightgray;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .para {
    font-size: 12px;
    margin: 15px 0px;
  }
  .warning {
    font-size: 14px;
    color: gray;
  }
  .submit-button {
    margin: 10px 0px;
    .submit {
      width: 100%;
    }
  }
}

.game-modal {
  .custom-input {
    margin-bottom: 10px;
    width: 500px;
  }
  .logo-label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: $secondaryColor;
    margin-bottom: 5px;
  }
  .upload-button {
    margin-bottom: 20px;
  }
  .submit-button {
    display: flex;
    justify-content: center;
  }
  .custom-button {
    padding: 10px;
  }
  .info-text {
    font-size: small;
    color: rgb(191, 191, 191);
    margin-bottom: 10px;
    margin-left: 2px;
  }
  .submit {
    width: 200px;
  }
  .image-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 240px;
      height: 150px;
      border: solid 2px $secondaryColor;
      object-fit: cover;
      border-radius: 5px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 600px) {
  .game-modal {
    .custom-input {
      width: 100%;
    }
  }
}

.existing-game-modal {
  display: flex;
  flex-direction: column;
  gap: 15px;
  .error {
    color: red;
  }
  .game-wrapper {
    display: flex;
    align-items: center;
    padding: 5px;
    gap: 10px;
    border-radius: 10px;
    cursor: pointer;
    border: solid 1px rgba(0, 0, 0, 0);
    color: #d4d4d4;

    .game-image {
      width: 60px;
      height: 60px;
      border: solid 2px $secondaryColor;
      border-radius: 10px;
    }
  }
  .game-wrapper:hover {
    border: solid 1px $secondaryColor;
  }
}

::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
  height: 7px;
  cursor: pointer;
}

/* Handle (thumb) */
::-webkit-scrollbar-thumb {
  background-color: $secondaryColor;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c9a431;
}

::-webkit-scrollbar-track {
  background-color: #505050;
}

::-webkit-scrollbar-corner {
  background-color: #222;
}

.info-entity-wrapper {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 600px;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  user-select: none;
  color: $secondaryColor;
  .indicator {
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-right: 4px;
    margin-bottom: 2px;
  }

  .active {
    background-color: rgb(0, 255, 0);
    border: solid 1px darkgreen;
  }

  .inactive {
    background-color: red;
    border: solid 1px darkred;
  }

  .info-entity {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .info-type {
      font-weight: 500;
      margin-right: 2px;
    }
    .info-value {
      color: white;
      margin-left: 8px;
      font-weight: 300;
      font-size: small;
      max-width: calc(min(35vw, 300px));
      overflow-x: auto;
    }
  }
  .change-button {
    display: flex;
    align-items: center;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 5px;
  }
  .change-button:hover {
    color: #fafa00;
  }
  .indicator-change-button {
    display: flex;
    align-items: center;
    font-size: 10px;
    cursor: pointer;
    margin-left: 5px;
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid transparent;
  }

  .indicator-change-button.activate {
    color: green;
    border-color: green;
    background-color: rgba(0, 255, 0, 0.1);
  }

  .indicator-change-button.deactivate {
    color: red;
    border-color: red;
    background-color: rgba(255, 0, 0, 0.1);
  }

  .indicator-change-button.loading {
    color: gray;
    border-color: gray;
    background-color: rgba(134, 134, 134, 0.1);
    cursor: default;
  }
}

.scale-down {
  margin-left: -60px;
  margin-right: -60px;
  transform: scale(80%) !important;
}

@media screen and (max-width: 600px) {
  .scale-down {
    margin-left: -60px;
    margin-right: -60px;
    transform: scale(70%) !important;
  }
}
