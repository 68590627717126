.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 99.7vh;
  border-radius: 10px;
}

.game-iframe {
  border-radius: 5px;
}

.loading-border {
  border: solid 5px rgba(233, 196, 81, 1);
  animation: blinkingBorder 1.5s infinite ease-in-out;
}

.loaded-border {
  border: solid 5px rgb(0, 170, 0);
}

.error-border {
  border: solid 5px rgba(170, 0, 0, 1);
}

@keyframes blinkingBorder {
  0% {
    border-color: rgba(233, 196, 81, 0);
  }
  50% {
    border-color: rgba(233, 196, 81, 1);
  }
  100% {
    border-color: rgba(233, 196, 81, 0);
  }
}
