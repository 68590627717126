@import "../../_variables";

.layout-wrapper {
  @include centerElementByFlex(column, space-between, unset);
  height: 100%;
  .body-wrapper {
    width: 100%;
    padding: 40px;
    height: 100%;
  }
}

.navbar-container {
  .desktop-navbar-wrapper {
    background: $primaryColor;
    display: flex;
    justify-content: center;
    padding: 10px 20px 0px 20px;
    .logo {
      width: 120px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .right-section {
      width: 100%;
      padding-top: 10px;
      @include centerElementByFlex(column, center, center);
      .upper-section {
        @include centerElementByFlex(row, space-between, center);
        width: 100%;
        .header {
          font-size: 34px;
          font-weight: 400;
          text-align: left;
          color: white;
          width: 60%;
          text-align: center;
          margin: 0px 10px;
        }
        .buttons-wrapper {
          @include centerElementByFlex(row, end, center);
          width: 40%;
          .signins {
            margin: 0px 20px;
            font-size: 12px;
          }
        }
      }
      .lower-section {
        width: 100%;
        @include centerElementByFlex(row, center, center);
        .menu-items {
          border-bottom: 2px solid white;
          width: 60%;
          margin-top: 10px;
          padding: 10px 0px;
          margin-bottom: 6px;

          ul {
            list-style-type: none;
            @include centerElementByFlex(row, space-between, center);
            li {
              color: white;
              transition: color 0.5s ease-in-out;
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              cursor: pointer;
            }
            li:hover {
              color: $secondaryColor;
            }
          }
        }
      }
    }
  }
  .mobile-navbar-wrapper {
    display: none;
  }
}

.custom-button-wrapper {
  background: $secondaryBackgroundColor !important;
  border: 1px solid #000000;
  border-radius: 6px;
  cursor: pointer;
  padding: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  user-select: none;
}

.custom-button-wrapper:hover {
  background: #edc754 !important;
}

.stripe-button-wrapper {
  padding: 10px 20px;
  background: #6772e5 !important;
  border-radius: 20px;
  cursor: pointer;
  .main-text {
    color: white;
    font-size: 20px;
    font-weight: 700;
  }
  .sub-text {
    color: white;
    font-size: 14px;
  }
}

.stripe-button-wrapper:hover {
  background: #4f5ce4 !important;
}

.disable-button {
  background: #b7b7b7 !important;
  color: black !important;
}

.custom-input-wrapper {
  color: white;
  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: $secondaryColor;
  }
  input {
    width: 100%;
    background: #878787;
    margin: 5px 0px;
    height: 45px;
    padding-left: 10px;
    border: none !important;
    border-radius: 6px;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
  input:disabled {
    color: rgb(59, 59, 59);
    font-weight: normal;
  }
  input[type="file"] {
    width: 100%;
    background-color: transparent;
    height: auto;
    padding-left: 0px;
    border: none !important;
  }

  input::placeholder {
    color: #d7d7d7;
  }

  input:focus-visible {
    outline: none !important;
  }
  .error-message {
    font-size: 13px;
    color: red;
  }
}

.custom-date-wrapper {
  color: black;
  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: $secondaryColor;
  }
  input {
    width: 100%;
    background: #878787;
    margin: 5px 0px;
    height: 45px;
    padding-left: 10px;
    border: none !important;
    border-radius: 6px;
    color: rgb(0, 0, 0);
    font-weight: 500;
  }
  input:disabled {
    color: rgb(59, 59, 59);
    font-weight: normal;
  }
  input[type="file"] {
    width: 100%;
    background-color: transparent;
    height: auto;
    padding-left: 0px;
    border: none !important;
  }

  input::placeholder {
    color: #d7d7d7;
  }

  input:focus-visible {
    outline: none !important;
  }
  .error-message {
    font-size: 13px;
    color: red;
  }
  .iti {
    width: 100%;
  }
}

.info-modal-counter {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 10px;
}

.custom-counter-wrapper {
  color: white;
  margin-top: 10px;
  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: $secondaryColor;
    width: 130px;
  }
  .inner-wrapper {
    display: flex;
    width: 130px;

    .display {
      width: 100%;
      background: #878787;
      margin: 5px 0px;
      height: 45px;
      padding-left: 10px;
      border: none !important;
      color: black;
      text-align: center;
      display: flex;
      align-items: center;
    }

    .display::placeholder {
      color: #d7d7d7;
    }

    .display:focus-visible {
      outline: none !important;
    }

    .counter {
      user-select: none;
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      button {
        width: 22.5px;
        height: 22.5px;
        border-radius: 1px !important;
        &:disabled {
          background-color: gray !important;
        }
      }
    }
  }

  .error-message {
    font-size: 13px;
    color: red;
  }
}

.custom-textarea-wrapper {
  color: white;
  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: $secondaryColor;
  }
  textarea {
    width: 100%;
    background: #878787;
    margin: 5px 0px;
    height: 100px;
    padding-left: 10px;
    border: none !important;
  }

  textarea::placeholder {
    color: #d7d7d7;
  }

  textarea:focus-visible {
    outline: none !important;
  }
  .error-message {
    font-size: 13px;
    color: red;
  }
}

.custom-select-wrapper {
  color: white !important;
  .label {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: $secondaryColor;
  }
  select {
    width: 100%;
    background: #878787;
    margin: 5px 0px;
    height: 45px;
    padding-left: 10px;
    border: none !important;
  }
  select::placeholder {
    color: #d7d7d7;
  }
  select:focus-visible {
    outline: none !important;
  }
  select:disabled {
    background: #d7d7d7;
  }
  .error-message {
    font-size: 13px;
    color: red;
  }
}

.custom-radio-form {
  color: white;
  .radio-group-wrapper {
    width: 100% !important;
    display: flex !important;
  }
  .wrapper {
    @include centerElementByFlex(row, center, center);
  }
  .title {
    color: white !important;
    font-size: 20px;
    margin-right: 10px;
  }
  .form-label {
    margin-right: 50px;
    color: white !important;
    .para {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      text-align: center;
    }
  }
}

.basic-radio-form {
  color: $secondaryColor;
  font-family: Arial, sans-serif;
  margin: 20px 0;

  .radio-title {
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
  }

  .radio-group {
    display: flex;
    gap: 20px;
  }

  .radio-label {
    display: flex;
    align-items: center;
    cursor: pointer;

    .radio-input {
      margin-right: 8px;
      cursor: pointer;
      width: 20px;
      height: 20px;
    }

    .radio-text {
      font-size: 16px;
    }
  }
}

.user-account-wrapper {
  border: 2px solid $secondaryColor;
  @include centerElementByFlex(row, center, center);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  .icon {
    color: $secondaryColor;
    font-size: 20px;
  }
}

.side-bar-drawer {
  .MuiPaper-root {
    background-color: black;
    color: $secondaryColor !important;
    .icon {
      color: $secondaryColor;
    }
  }

  .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    box-shadow: none !important;
    margin-top: 30px;
  }
}

.footer-wrapper {
  background: $secondaryColor;
  position: relative;
  overflow: hidden;
  .upper-middle-wrapper {
    padding: 20px 20px 10px 40px;
    .upper-section {
      width: 100%;
      margin-bottom: 20px;
      @include centerElementByFlex(row, space-between, center);
      .first-section {
        .logo {
          width: 80px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .contact-header {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          margin: 10px 0px;
        }
        .contact-item {
          margin: 10px 0px;
          .header {
            @include centerElementByFlex(row, flex-start, center);
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            .name {
              margin-left: 10px;
            }
          }
          p {
            font-weight: 400;
            font-size: 14px;
            margin-left: 26px;
            a {
              text-decoration: none;
              color: #000000 !important;
            }
          }
        }
      }
      .second-third-wrapper {
        width: 100%;
        margin-left: 20px;
        @include centerElementByFlex(row, space-around, center);
        .section {
          padding: 0px 20px;
          .header {
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            text-align: center;
            margin-bottom: 15px;
          }
          .menu {
            ul {
              list-style-type: none;
              @include centerElementByFlex(column, center, center);
              li {
                text-decoration: none;
                margin: 10px 0px;
                cursor: pointer;
                font-size: 15px;
              }
              li:hover {
                text-decoration-line: underline;
                color: blue;
              }
            }
          }
        }
      }
    }
    .middle-section {
      @include centerElementByFlex(row, space-between, unset);
      .first-section {
        h2 {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
        }
        .app-store {
          @include centerElementByFlex(row, center, center);
          width: 100%;
          margin-top: 5px;
          .image-wrapper {
            height: 50px;
            margin-right: 20px;
            width: 130px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              cursor: pointer;
            }
            img:hover {
              box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
                rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
                rgba(0, 0, 0, 0.07) 0px 8px 16px,
                rgba(0, 0, 0, 0.07) 0px 16px 32px,
                rgba(0, 0, 0, 0.07) 0px 32px 64px;
            }
          }
          .image-wrapper:nth-child(2) {
            width: 150px;
          }
        }
      }
      .second-section {
        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
        }
        .social-lists {
          margin-top: 10px;
          @include centerElementByFlex(row, space-between, center);
          .icon-wrapper {
            @include centerElementByFlex(row, center, center);
            border: 1px solid #6e767d;
            width: 30px;
            height: 30px;
            border-radius: 50px;
            margin: 0px 10px;
            cursor: pointer;
            .icon {
              color: #6e767d;
            }
          }
          .icon-wrapper:hover {
            border: 1px solid #b5b6b7;
            .icon {
              color: #b5b6b7;
            }
          }
        }
      }
      .third-section {
        width: 30%;
        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
        }
        .input-wrapper {
          margin-top: 10px;
          @include centerElementByFlex(row, center, center);
          .subscribe-button {
            background: black;
            border: none !important;
            color: white;
            padding: 10px 20px;
            margin-left: 20px;
            cursor: pointer;
          }
          .input {
            background: #ffffff;
            border: 1px solid #d7dbdf;
            box-shadow: 0px 1px 0px rgba(17, 24, 28, 0.08);
            height: 40px;
            width: 100%;
            padding: 0px 10px;
          }
          .input::placeholder {
            font-size: 12px;
          }
          input:focus-visible {
            outline: none !important;
          }
        }
      }
    }
  }

  .lower-section {
    @include centerElementByFlex(row, center, center);
    .rights-reserved {
      width: 80%;
      border-top: 1px solid #05abf3;
      text-align: center;
      padding: 13px 0px;
      font-size: 14px;
    }
  }
  .ball-wrapper {
    position: absolute;
    top: -120px;
    right: -100px;
    width: 350px;
    height: 350px;
    border: 1px solid #05abf3;
    border-radius: 50%;
    @include centerElementByFlex(row, center, center);
    .ball {
      width: 330px;
      height: 330px;
      background-color: #0a093e;
      border-radius: 50%;
    }
  }
}

.success-message {
  color: white;
  @include centerElementByFlex(column, center, center);
  .icon-wrapper {
    .icon {
      color: $secondaryColor;
      font-size: 50px;
    }
  }
  .title {
    font-size: 20px;
    margin-top: 10px;
  }
}

.table-action-button-wrapper {
  @include centerElementByFlex(row, center, center);
  .icon-wrapper {
    border: 1px solid $secondaryColor;
    border-radius: 50%;
    margin: 0px 5px;
    @include centerElementByFlex(row, center, center);
    cursor: pointer;
    .icon {
      font-size: 20px;
      margin: 10px;
      color: $secondaryColor;
    }
  }
}

.custom-table-loader-wrapper {
  position: absolute;
  @include centerElementByFlex(row, center, center);
  display: flex;
  z-index: 100;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

// CUSTOM DIALOG CSS

.MuiDialog-paper {
  background-color: black !important;
  border: 4px solid $secondaryColor !important;
  color: white !important;
  border-radius: 24px !important;
  min-width: 300px;
  min-height: 200px;
}

.dialog-container .dialog-title {
  .title-wrapper {
    @include centerElementByFlex(row, space-between, center);
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      color: $secondaryColor;
      text-decoration: underline;
    }
    .icon {
      color: $secondaryColor;
      cursor: pointer;
    }
    .back-button {
      cursor: pointer;
      margin-right: 5px;
    }
  }
}

// CUSTOM CELL CSS

.custom-cell-wrapper {
  border: 1px solid #e9c451 !important;
  background: black;
  color: white !important;
}
.custom-column-cell-wrapper {
  border: 1px solid #e9c451 !important;
  background: #878787;
  color: white !important;
}

.otp-verification-wrapper {
  @include centerElementByFlex(column, center, center);
  color: white;
  .header {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
  }
  .para {
    width: 700px;
    margin: 15px 0px 50px 0px;
    text-align: center;
    .email {
      color: $secondaryColor;
      margin-left: 5px;
    }
  }
  .otp-wrapper {
    @include centerElementByFlex(row, center, center);
    .otp {
      border: 1px solid rgba(239, 239, 239, 0.8);
      outline: none !important;
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 58px;
      height: 77px;
      text-align: center;
      margin: 0px 20px;
    }
  }
  .resend-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 40px;
    span {
      margin: 0px 5px;
    }
    span:nth-child(2) {
      color: $secondaryColor;
      cursor: pointer;
    }
  }
  .continue-button {
    width: 300px;
    margin-top: 50px;
  }
}

.custom-arrow-left {
  position: absolute;
  top: calc(50% - 43px);
  left: -42px;
  z-index: 1;
}

.custom-arrow-right {
  position: absolute;
  top: calc(50% - 43px);
  right: 42px;
  z-index: 1;
}

@media screen and (max-width: 1135px) {
  .navbar-container {
    .desktop-navbar-wrapper {
      .logo {
        width: 100px;
      }
      .right-section {
        padding-top: 10px;

        .upper-section {
          .header {
            font-size: 30px;
            width: 60%;
          }
          .buttons-wrapper {
            width: 50%;
            .signins {
              margin: 0px 10px;
            }
          }
        }
        .lower-section {
          .menu-items {
            ul {
              li {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .navbar-container {
    .desktop-navbar-wrapper {
      display: none;
    }
    .mobile-navbar-wrapper {
      display: block;
      @include centerElementByFlex(row, space-between, center);
      padding: 10px 15px;
      background: $secondaryBackgroundColor;
      .logo {
        height: 80px;
        width: 80px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .mobile-user-account {
        width: 40px;
        height: 40px;
        border: 1px solid black;
        cursor: pointer;
        .icon {
          color: black;
          font-size: 16px;
        }
      }
    }
  }
  .footer-wrapper {
    background: $secondaryColor;
    position: relative;
    overflow: hidden;
    .upper-middle-wrapper {
      padding: 40px 0px 15px 0px;
      .upper-section {
        width: 100%;
        margin-bottom: 20px;
        @include centerElementByFlex(column, center, center);
        .first-section {
          @include centerElementByFlex(column, center, center);
          width: 100%;
          .logo {
            width: 80px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .contact-header {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            margin: 10px 0px;
            text-align: center;

            width: 100%;
          }
          .contact-item {
            margin: 10px 0px;

            .header {
              @include centerElementByFlex(row, center, center);
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              .name {
                margin-left: 10px;
              }
            }
            p {
              font-weight: 400;
              font-size: 13px;
              margin-left: 0px;
              margin-top: 10px;
            }
          }
        }
        .second-third-wrapper {
          width: 100%;
          margin-left: 0px;
          margin-top: 20px;
          @include centerElementByFlex(row, space-evenly, center);
          .section {
            padding: 0px 0px;

            .header {
              font-style: normal;
              font-weight: 400;
              font-size: 22px;
              text-align: center;
              margin-bottom: 15px;
            }
            .menu {
              ul {
                list-style-type: none;
                @include centerElementByFlex(column, center, center);
                li {
                  margin: 10px 0px;
                  cursor: pointer;
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
      .middle-section {
        @include centerElementByFlex(column, center, center);
        padding: 0px 15px;
        .first-section {
          margin: 20px 0px;

          width: 100%;

          h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
          }
          .app-store {
            @include centerElementByFlex(row, space-evenly, center);
            width: 100%;
            margin-top: 10px;
            .image-wrapper {
              height: 50px;
              margin-right: 0px;
              width: 130px;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .image-wrapper:nth-child(2) {
              width: 150px;
            }
          }
        }
        .second-section {
          //  border: 1px solid black;
          margin: 30px 0px;
          width: 100%;
          h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            text-align: center;
          }
          .social-lists {
            margin-top: 15px;
            @include centerElementByFlex(row, space-evenly, center);
            .icon-wrapper {
              @include centerElementByFlex(row, center, center);
              border: 1px solid #6e767d;
              width: 30px;
              height: 30px;
              border-radius: 50px;
              margin: 0px 10px;
              cursor: pointer;
              .icon {
                color: #6e767d;
              }
            }
            .icon-wrapper:hover {
              border: 1px solid #b5b6b7;
              .icon {
                color: #b5b6b7;
              }
            }
          }
        }
        .third-section {
          width: 100%;
          h2 {
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            text-align: center;
            margin-bottom: 20px;
          }
          .input-wrapper {
            margin-top: 10px;
            @include centerElementByFlex(column, center, center);
            .subscribe-button {
              background: black;
              border: none !important;
              color: white;
              padding: 10px 20px;
              margin-left: 0px;
              cursor: pointer;
              width: 100%;
              margin-top: 10px;
            }
            .input {
              background: #ffffff;
              border: 1px solid #d7dbdf;
              box-shadow: 0px 1px 0px rgba(17, 24, 28, 0.08);
              height: 40px;
              width: 100%;
              padding: 0px 10px;
            }
            .input::placeholder {
              font-size: 12px;
            }
            input:focus-visible {
              outline: none !important;
            }
          }
        }
      }
    }

    .lower-section {
      @include centerElementByFlex(row, center, center);
      .rights-reserved {
        width: 80%;
        border-top: 1px solid #05abf3;
        text-align: center;
        padding: 13px 0px;
        font-size: 14px;
      }
    }
    .ball-wrapper {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .otp-verification-wrapper {
    // border: 1px solid white;
    @include centerElementByFlex(column, center, center);
    .header {
      font-size: 40px;
      font-weight: 700;
      text-align: center;
    }
    .para {
      // border: 1px solid green;
      width: 100%;
      margin: 15px 0px 50px 0px;
      text-align: center;
    }
    .otp-wrapper {
      // border: 1px solid green;
      @include centerElementByFlex(row, center, center);
      .otp {
        border: 1px solid rgba(239, 239, 239, 0.8);
        outline: none !important;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 58px;
        height: 77px;
        text-align: center;
        margin: 0px 10px;
      }
    }
    .resend-wrapper {
      width: 100%;
      text-align: center;
      margin-top: 40px;
      span {
        margin: 0px 5px;
      }
      span:nth-child(2) {
        color: $secondaryColor;
        cursor: pointer;
      }
    }
    .continue-button {
      width: 300px;
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 500px) {
  .layout-wrapper {
    @include centerElementByFlex(column, space-between, unset);
    .body-wrapper {
      width: 100%;
      padding: 40px 15px;
    }
  }
  .custom-radio-form {
    .radio-group-wrapper {
      display: flex !important;
      flex-direction: column;
    }
  }
  .dialog-container .dialog-title {
    .title-wrapper {
      .title {
        font-weight: 700;
        font-size: 15px;
      }
      .back-button {
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
}

.small-text {
  font-size: 12px;
  display: flex;
  align-items: center;
  max-width: 200px;
  overflow-x: hidden;
}

.flex-child {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 40px;
}

.full-max-width {
  max-width: 100% !important;
  margin-left: 5px;
  margin-right: 5px;
}

.stripe-accounts-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  .title {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    text-decoration: underline;
    color: $secondaryColor;
    margin-bottom: 20px;
  }
  .table-wrapper {
    width: 100%;
    max-width: 900px;
    margin-bottom: 10px;
    border: solid 1px;
    border-color: $secondaryColor;
  }
  .button-group {
    width: 100%;
    max-width: 900px;
    display: flex;
    justify-content: space-around;
  }
}

.donation-page {
  color: white;
  .title {
    color: $secondaryColor;
    display: flex;
    justify-content: center;
    font-size: xx-large;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .participant-data {
    margin-bottom: 20px;
    .name {
      color: $secondaryColor;
    }
  }
  .input-title {
    color: $secondaryColor;
    font-size: large;
    font-weight: 500;
  }
  .custom-input {
    max-width: 250px;
    width: 100%;
  }
  .input-footer {
    font-size: small;
  }
  .donate-btn {
    max-width: 250px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }
}

.red-button {
  background: linear-gradient(180deg, #fe5a5a 0%, #e91a1a 100%) !important;
}

.red-button:hover {
  background: linear-gradient(180deg, #fc7b7b 0%, #e82a2a 100%) !important;
}

.green-button {
  background: linear-gradient(180deg, #6de46d 0%, #44be44 100%) !important;
}

.green-button:hover {
  background: linear-gradient(180deg, #49d047 0%, #1fb224 100%) !important;
}
.navigate-text {
  color: $secondaryColor;
  cursor: pointer;
  user-select: none;
  display: none;
  border: solid 2px $secondaryColor;
  padding: 5px;
  border-radius: 4px;
  width: fit-content;
  margin-bottom: 10px;
}

@media screen and (max-width: 800px) {
  .navigate-button {
    display: none;
  }
  .navigate-text {
    display: block;
  }
}

.text-with-link {
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: underline;
  color: $secondaryColor;
  cursor: pointer;
  user-select: none;
}

.privacy-policy {
  max-width: 80%;
  margin-left: 20px;
  color: white;
  h1 {
    color: $secondaryColor;
    margin-bottom: 20px;
  }
  h2 {
    color: $secondaryColor;
    text-decoration: underline;
    margin-top: 30px;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 10px;
  }
  ul {
    margin-bottom: 10px;
    li {
      margin-bottom: 2px;
      color: #d7d7d7;
      b {
        color: $secondaryColor;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .privacy-policy {
    margin-left: 10px;
    max-width: 90%;
    color: white;
    h1 {
      color: $secondaryColor;
      margin-bottom: 15px;
    }
    h2 {
      color: $secondaryColor;
      text-decoration: underline;
      margin-top: 20px;
      margin-bottom: 4px;
    }
    p {
      margin-bottom: 8px;
    }
    ul {
      margin-bottom: 8px;
      li {
        margin-bottom: 5px;
        color: #d7d7d7;
        b {
          color: $secondaryColor;
        }
      }
    }
  }
}

.iti__dropdown-content {
  background-color: black !important;
  padding: 5px !important;
}
.iti__country-name {
  color: $secondaryColor;
}
.iti__dial-code {
  color: white;
}
.iti__highlight {
  background-color: rgb(33, 33, 33) !important;
}
.iti__search-input {
  background-color: rgb(42, 42, 42) !important;
  color: white !important;
}

.margin-top {
  margin-top: 20px;
}
@media screen and (max-width: 800px) {
  .hide-on-mobile {
    display: none;
  }
}

.donation-sponsor-wrapper {
  margin-top: 10px;
  margin-bottom: 20px;

  .sponser-info {
    width: 80%;
    max-width: 400px;
    margin: 20px auto;
  }
  .sponser-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .blurry {
    opacity: 50%;
    pointer-events: none;
    user-select: none;
    .error-message {
      display: none;
    }
  }
  .logo {
    width: 250px;
    height: 200px;
    display: flex;
    font-size: larger;
    font-weight: 500;
    color: black;
    justify-content: center;
    align-items: center;
    background: #d9d9d9;
    border: solid 5px $secondaryColor;
    border-radius: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .logo.drag-over {
    background: #b0b0b0;
  }
  .logo-error {
    color: red;
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.sponsor-check {
  margin-left: -10px;
  margin-bottom: 5px;
  margin-top: 5px;
}

@media screen and (min-width: 800px) {
  .donation-sponsor-wrapper {
    display: flex;
    justify-content: space-around;
    min-height: 500px;
    margin-top: 10px;
    margin-bottom: 20px;
    flex-wrap: wrap;

    .sponser-info {
      max-width: 400px;
      margin-top: 40px;
      margin-bottom: 40px;
      margin: 20px;
    }
  }
}

.sponsor-table-wrapper {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 12px 6px rgba(233, 196, 81, 0.5);
}
.add-sponsor-button {
  align-self: flex-start;
}

.centered-text {
  display: flex;
  align-items: center;
}

.sponsor-list {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 15px;
}

.sponsor-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 15px;
  border: 2px solid $secondaryColor;
  transition: transform 0.3s ease;
}
.sponsor-image:hover {
  transform: scale(1.15);
}

.game {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 5px;
  border: solid 2px black;

  .leaderboard-image {
    position: absolute;
    top: 10px;
    left: calc(100% - 60px);
    z-index: 1;
    width: 50px;
    height: 50px;
    opacity: 0%;
    border-radius: 100%;
    padding: 10px;
    overflow: visible;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    cursor: pointer;
  }

  .leaderboard-image:hover {
    background-color: rgba(87, 65, 0, 0.5);
    backdrop-filter: blur(4px);
  }

  .active {
    opacity: 100%;
  }

  img {
    width: 350px;
    height: 200px;
    object-fit: fill;
    border-radius: 10px;
    margin-bottom: 5px;
  }
  .name {
    color: $secondaryColor;
    max-width: 350px;
    text-align: center;
    font-size: x-large;
  }
  .desc {
    color: rgb(167, 167, 167);
    font-size: small;
    max-width: 350px;
    text-align: center;
    margin-bottom: 5px;
  }
  .custom-button {
    width: 200px;
    padding: 10px;
    margin-bottom: 10px;
  }
  .enabled {
    background-image: linear-gradient(
      180deg,
      #91f586 0%,
      #3ce753 100%
    ) !important;
  }
  .enabled:hover {
    background-image: linear-gradient(
      180deg,
      #3ce753 0%,
      #3ce753 100%
    ) !important;
  }
  .disabled {
    background-image: linear-gradient(
      180deg,
      #fd5656 0%,
      #f22a2a 100%
    ) !important;
  }
}
.game:hover {
  border: solid 2px $secondaryColor;
  background-color: #171717;
  border-radius: 10px;
}

.leaderboard-modal {
  padding: 20px;
  background: #1e1e1e;
  border-radius: 8px;

  .leaderboard-list {
    list-style: none;
    padding: 0px;
    margin: 0;
    max-height: 440px;
    overflow-y: auto;
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .leaderboard-item {
    display: flex;
    align-items: center;
    background: #333; // Darker background for list items
    border: 1px solid #444; // Subtle border for better visibility
    border-radius: 8px;
    padding: 20px;
    margin-left: 5px;
    margin-bottom: 15px;
    width: 420px;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.02);
    }

    .rank {
      font-size: 1.8rem;
      font-weight: bold;
      color: #e9c451;
      margin-right: 20px;
    }

    .details {
      h3 {
        margin: 0;
        font-size: 1.3rem;
        font-weight: 600;
        color: #e9c451;
      }

      p {
        margin: 5px 0 0;
        font-size: 1rem;
        color: #bbb;
      }
    }
  }

  /* Special colors for top 3 ranks matching the primary color */
  .first-place {
    background: #e0b84a; // Slightly golden color for 1st place
    .details h3,
    .details p,
    .rank {
      color: #1e1e1e; // Dark text for contrast
    }
  }

  .second-place {
    background: #c7b17f; // Silver-like color for 2nd place
    .details h3,
    .details p,
    .rank {
      color: #1e1e1e; // Dark text for contrast
    }
  }

  .third-place {
    background: #a88953; // Bronze-like color for 3rd place
    .details h3,
    .details p,
    .rank {
      color: #1e1e1e; // Dark text for contrast
    }
  }

  /* Styling scrollbar */
  .leaderboard-list::-webkit-scrollbar {
    width: 8px;
  }

  .leaderboard-list::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 4px;
  }

  .leaderboard-list::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

.blur-background {
  backdrop-filter: blur(10px);
}
