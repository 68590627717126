@mixin centerElementByFlex($direction, $justifyContent, $alignItems) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
  flex-direction: $direction;
}

$secondaryBackgroundColor: linear-gradient(180deg, #f6e2a4 0%, #eac655 100%);
$primaryColor: #313131;
$secondaryColor: #e9c451;

@mixin text($size) {
  color: $secondaryColor;
  text-decoration-line: underline;
  font-size: $size;
}
