@import "../../../_variables";

.home-main-wrapper,
.about-wrapper,
.game-main-wrapper {
  max-width: 1100px;
  margin: 10px auto;
}
.home_description {
  color: #fff;
  text-align: justify;
  font-size: 20px;
  word-spacing: normal;
}
.home-btn-wrapper {
  @include centerElementByFlex(row, center, center);
  margin: 30px;
}
.home-btn-wrapper-top {
  @include centerElementByFlex(row, center, center);
  .home_description-btn-top {
    padding: 30px 40px;
    margin: 10px;
  }
}
.home_description-btn {
  padding: 20px;
  margin: 10px;
}
.home_description-btn2 {
  padding: 50px 80px;
  margin: 10px;
  font-size: 24px;
}
.cards-wrapper {
  width: 100%;
  @include centerElementByFlex(row, null, center);
}
.card-img-wrapper,
.card-para-wrapper {
  width: 50%;
  @include centerElementByFlex(row, center, center);
}

.card-para-wrapper p {
  color: #fff;
  font-size: 28px;
  text-align: justify;
}
.cards-wrapper2 {
  width: 100%;
  @include centerElementByFlex(row, null, center);
}
.card-img-wrapper2,
.card-para-wrapper2 {
  width: 50%;
  @include centerElementByFlex(row, center, center);
}

.card-para-wrapper2 p {
  color: #fff;
  font-size: 28px;
  text-align: justify;
}

.card-img {
  height: 70vh;
  width: 90%;
  height: 30%;
}
.scaner-img {
  width: 80%;
  padding-top: 50px;
}
.home-table-wrapper {
  margin-top: 20px;
  .custom-btn {
    margin-top: 10px;
  }
}

// =============================== About =====================================

.about-first-heading {
  @include text(24px);
}
.about-first-para {
  color: #fff;
  font-size: 24px;
}
.about-2nd-section-wrapper {
  width: 100%;
  @include centerElementByFlex(row, center, none);
  margin-top: 20px;
}
.about-img-wrapper {
  width: 30%;
}
.about-para-wrapper {
  width: 70%;
}
.about-img {
  height: 100%;
  width: 100%;
}
.about-2nd-para {
  color: #fff;
  font-size: 24px;
  padding: 20px;
}
.about-3nd-para {
  color: #fff;
  font-size: 24px;
}
// =============================== About =====================================

// =============================== Games =============================
.game-title {
  @include text(24px);
}
// =============================== Supports =============================
.support-1stsection-wrapper {
  background: $primaryColor;
  padding: 20px;
  .supports-title {
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    text-decoration-line: underline;
    text-align: center;
    color: $secondaryColor;
  }
  .menu-support {
    @include centerElementByFlex(row, space-evenly, center);
    .card-support {
      @include centerElementByFlex(column, space-evenly, center);
    }
    .icon-support {
      font-size: 50px;
      color: $secondaryColor;
    }
    .header-support {
      color: $secondaryColor;
      margin-top: 10px;
    }
    .paragraph-support {
      color: #fff;
      margin-top: 10px;
    }
  }
}

.support-2nd-section-wrapper {
  @include centerElementByFlex(row, none, none);
  max-width: 100%;
  margin: 20px 40px;
  .support-img-wrapper {
    width: 50%;
    .support-profile-img {
      width: 100%;
      height: 70vh;
    }
  }
  .support-input-wrapper {
    width: 50%;
    padding: 10px;
  }
  .support-form-button {
    width: 100%;
    margin-top: 10px;
  }
}

// ===================== Media Querry ====================//

@media screen and (max-width: 1000px) {
  .home-main-wrapper,
  .about-wrapper,
  .game-main-wrapper {
    max-width: 100%;
    margin: 10px 60px;
  }
  .cards-wrapper {
    width: 100%;
    @include centerElementByFlex(column, center, center);
  }
  .card-img-wrapper,
  .card-para-wrapper {
    width: 100%;
  }
  .card-para-wrapper p {
    font-size: 24px;
    margin: 20px;
  }
  .cards-wrapper2 {
    width: 100%;
    @include centerElementByFlex(column-reverse, center, center);
  }
  .card-img-wrapper2,
  .card-para-wrapper2 {
    width: 100%;
  }
  .card-para-wrapper2 p {
    font-size: 24px;
    margin: 20px;
  }
  .card-img {
    width: 100%;
  }
  .scaner-img {
    width: 80%;
  }
  .home_description-btn2 {
    padding: 30px 50px;
    margin: 5px;
    font-size: 16px;
  }

  .about-first-heading {
    @include text(22px);
  }
  .about-first-para {
    font-size: 18px;
  }
  .about-2nd-para {
    font-size: 18px;
  }
  .about-img {
    height: 90%;
  }
  .about-3nd-para {
    font-size: 18px;
  }
  .support-2nd-section-wrapper {
    @include centerElementByFlex(column, none, none);
    margin: 20px;
    .support-img-wrapper {
      width: 100%;
      .support-profile-img {
        width: 100%;
        height: 70vh;
      }
    }
    .support-input-wrapper {
      width: 100%;
      padding: 0px;
    }
  }
}

@media screen and (max-width: 768px) {
  .home-main-wrapper,
  .about-wrapper,
  .game-main-wrapper {
    margin: 10px 40px;
  }
  .card-para-wrapper2 p {
    font-size: 22px;
    margin: 20px;
  }
  .home_description-btn2 {
    padding: 20px 40px;
    margin: 5px;
    font-size: 12px;
  }
  // =============================== About =============================
  .about-2nd-section-wrapper {
    @include centerElementByFlex(column, center, none);
  }
  .about-img-wrapper {
    width: 100%;
  }
  .about-para-wrapper {
    width: 100%;
  }
  .about-2nd-para {
    padding: 0px;
  }
  .support-1stsection-wrapper {
    .supports-title {
      font-weight: 700;
      font-size: 24px;
    }
    .menu-support {
      .icon-support {
        font-size: 28px;
      }
      .paragraph-support {
        font-size: 12px;
      }
      .header-support {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .home-main-wrapper,
  .about-wrapper,
  .game-main-wrapper {
    margin: 10px 30px;
  }
  .card-para-wrapper2 p {
    font-size: 14px;
    margin: 10px;
  }
  .card-para-wrapper p {
    font-size: 14px;
    margin: 10px;
  }
  .home_description-btn2 {
    padding: 15px 30px;
    margin: 2px;
    font-size: 10px;
  }
  .scaner-img {
    height: 40vh;
  }
  .home_description {
    font-size: 14px;
    word-spacing: 0%;
  }
  .home_description-btn2 {
    width: 50%;
    height: 10vh;
    @include centerElementByFlex(row, center, center);
    font-size: 8px;
  }
  .home-btn-wrapper {
    @include centerElementByFlex(row, center, center);
    margin: 10px;
  }
  .home-btn-wrapper-top {
    @include centerElementByFlex(column, none, none);

    .home_description-btn-top {
      padding: 20px 30px;
      margin: 5px;
      width: 100%;
      font-size: 10px;
      font-weight: bold;
    }
  }
  // =============================== Games =============================
  .game-title {
    @include text(16px);
  }
  // =============================== About  =============================
  .about-first-heading {
    @include text(14px);
  }
  .about-first-para {
    font-size: 14px;
  }
  .about-2nd-para {
    font-size: 14px;
  }
  .about-img {
    height: 70%;
  }
  .about-3nd-para {
    font-size: 14px;
  }
  .support-2nd-section-wrapper {
    .support-img-wrapper {
      .support-profile-img {
        width: 100%;
        height: 50vh;
      }
    }
    .support-input-wrapper {
      width: 100%;
      padding: 0px;
    }
  }
}
