@import "../../../_variables";

.account-type-selection-wrapper {
  border: 8px solid $secondaryColor;
  margin: 50px auto;
  max-width: 550px;
  width: 100%;
  min-width: 320px;
  padding: 30px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .custom-btn {
    max-width: 380px;
    width: 100%;
    min-width: 250px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 24px;
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    color: $secondaryColor;
    text-decoration: underline;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .text {
    font-style: normal;
    font-size: 20px;
    color: white;
    margin-bottom: 10px;
  }
}

.primary-admin-login-wrapper {
  border: 2px solid $secondaryColor;
  margin: 50px auto;
  max-width: 350px;
  width: 100%;
  min-width: 250px;
  padding: 20px;
  border-radius: 8px;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: $secondaryColor;
    text-decoration: underline;
    margin-bottom: 10px;
  }
  .input {
    margin: 15px 0px;
  }
  .primary-admin-button-wrapper {
    @include centerElementByFlex(row, center, center);
    width: 100%;
    .login-button {
      margin: auto;
      width: 100%;
    }
  }
  a {
    color: $secondaryColor;
    font-size: small;
    margin-bottom: 20px;
  }
}

.participant-login-wrapper {
  border: 2px solid $secondaryColor;
  margin: 50px auto;
  max-width: 350px;
  width: 100%;
  min-width: 250px;
  padding: 20px;
  border-radius: 8px;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: $secondaryColor;
    text-decoration: underline;
    margin-bottom: 10px;
  }
  .input {
    margin: 15px 0px;
  }
  a {
    color: $secondaryColor;
    font-size: small;
    margin-bottom: 20px;
  }
  .participant-button-wrapper {
    @include centerElementByFlex(row, center, center);
    width: 100%;
    .login-button {
      margin: auto;
      width: 100%;
    }
  }
}

.teammember-login-wrapper {
  border: 2px solid $secondaryColor;
  margin: 50px auto;
  max-width: 350px;
  width: 100%;
  min-width: 250px;
  padding: 20px;
  border-radius: 8px;

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: $secondaryColor;
    text-decoration: underline;
    margin-bottom: 10px;
  }
  .input {
    margin: 15px 0px;
  }
  a {
    color: $secondaryColor;
    font-size: small;
    margin-bottom: 20px;
  }
  .participant-button-wrapper {
    @include centerElementByFlex(row, center, center);
    width: 100%;
    .login-button {
      margin: auto;
      width: 100%;
    }
  }
}

.admin-signup-wrapper {
  .upper-section {
    padding: 20px;
    .heading {
      font-style: normal;
      font-weight: 700;
      font-size: 44px;
      text-decoration-line: underline;
      text-align: center;
      color: $secondaryColor;
    }
    .para {
      font-style: normal;
      font-weight: 500;
      max-width: 800px;
      margin: auto;
      font-size: 24px;
      text-align: center;
      color: white;
    }
    .menu {
      margin-top: 30px;
      @include centerElementByFlex(row, space-evenly, center);
      .card {
        @include centerElementByFlex(column, center, center);
        .icon {
          font-size: 50px;
          color: $secondaryColor;
        }
        .header {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          text-align: center;
          color: $secondaryColor;
          margin: 10px 0px;
        }
        .paragraph {
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          text-align: center;
          color: white;
        }
      }
    }
  }
  .lower-section {
    padding: 20px;
    .existing-button {
      @include centerElementByFlex(row, center, center);
      margin: 30px 0px;
    }
    .condition-list {
      color: white;
      margin: 20px 0px;
      display: flex;
      .para {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        display: inline;
        .link {
          color: $secondaryColor;
          text-decoration: underline;
          font-weight: 500;
          margin-left: 5px;
        }
      }
    }
    .submit-button {
      width: 300px;
      height: 50px;
      font-weight: 700;
      font-size: 20px;
      margin: 0px;
      @include centerElementByFlex(row, center, center);
      margin-top: 20px;
    }
  }
}

.participant-signup-wrapper {
  .upper-section {
    background: $primaryColor;
    padding: 20px;
    .heading {
      font-style: normal;
      font-weight: 700;
      font-size: 44px;
      text-decoration-line: underline;
      text-align: center;
      color: $secondaryColor;
    }
    .menu {
      margin-top: 30px;
      @include centerElementByFlex(row, space-evenly, center);
      .card {
        @include centerElementByFlex(column, center, center);
        width: 200px;
        .icon {
          font-size: 50px;
          color: $secondaryColor;
        }
        .header {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          text-align: center;
          color: $secondaryColor;
          margin: 10px 0px;
        }
        .paragraph {
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          text-align: center;
          color: white;
        }
      }
    }
  }
  .lower-section {
    padding: 20px;

    .existing-button {
      @include centerElementByFlex(row, center, center);
      margin: 30px 0px;
    }
    .condition-list {
      color: white;
      margin: 20px 0px;
      display: flex;
      .para {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        display: inline;
        .link {
          color: $secondaryColor;
          text-decoration: underline;
          font-weight: 500;
          margin-left: 5px;
        }
      }
    }
    .submit-button {
      width: 300px;
      height: 50px;
      font-weight: 700;
      font-size: 20px;
      margin: 0px;
      @include centerElementByFlex(row, center, center);
      margin-top: 20px;
    }
  }
}

.login-wrapper {
  .input {
    margin: 10px 0px;
  }
  .login-button {
    width: 100%;
    margin: 10px 0px;
  }
  a {
    color: $secondaryColor;
    font-size: small;
  }
}

.teammember-signup-wrapper {
  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      color: white;
    }
  }

  .upper-section {
    background: $primaryColor;
    padding: 20px;
    .heading {
      font-style: normal;
      font-weight: 700;
      font-size: 44px;
      text-decoration-line: underline;
      text-align: center;
      color: $secondaryColor;
    }
    .menu {
      margin-top: 30px;
      @include centerElementByFlex(row, space-evenly, center);
      .card {
        @include centerElementByFlex(column, center, center);
        .icon {
          font-size: 50px;
          color: $secondaryColor;
        }
        .header {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          text-align: center;
          color: $secondaryColor;
          margin: 10px 0px;
        }
        .paragraph {
          text-align: center;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          text-align: center;
          color: white;
        }
      }
    }
  }
  .lower-section {
    padding: 20px;
    .existing-button {
      @include centerElementByFlex(row, center, center);
      margin: 30px 0px;
    }
    .choose-guardian-wrapper {
      margin: 20px 0px;
      .is-guardian {
        color: white;
      }
    }
    .condition-list {
      color: white;
      margin: 20px 0px;
      display: flex;
      .para {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        display: inline;
        .link {
          color: $secondaryColor;
          text-decoration: underline;
          font-weight: 500;
          margin-left: 5px;
        }
      }
    }
    .submit-button {
      width: 300px;
      height: 50px;
      font-weight: 700;
      font-size: 20px;
      margin: 0px;
      @include centerElementByFlex(row, center, center);
      margin-top: 20px;
    }
  }
}

.email-verification-wrapper {
  color: white;
  @include centerElementByFlex(column, space-evenly, center);
  height: 250px;
  .icon-wrapper {
    .icon {
      font-size: 30px;
      color: $secondaryColor;
    }
    .cancel-icon {
      color: red;
      font-size: 30px;
    }
  }
  .paragraph {
    font-size: 18px;
    font-weight: 700;
  }
}

.resend-email-verification-wrapper {
  color: white;
  @include centerElementByFlex(column, space-evenly, center);
  height: 250px;
  .icon-wrapper {
    .icon {
      font-size: 30px;
      color: $secondaryColor;
    }
    .cancel-icon {
      color: red;
      font-size: 30px;
    }
  }
  .middle-section {
    .paragraph {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
    }
    .paragraph2 {
      text-align: center;
    }
  }
}

.confirm-organization-dialog-wrapper {
  @include centerElementByFlex(column, space-evenly, center);
  height: 200px;
  .para {
    color: $secondaryColor;
    text-align: center;
  }
  .actions-wrapper {
    @include centerElementByFlex(row, center, center);
    .button {
      width: 100px;
      padding: 10px 0px;
      margin: 0px 5px;
    }
  }
}

.forgot-password-wrapper {
  // height: 100vh;
  @include centerElementByFlex(row, center, center);
  .container {
    max-width: 600px;
    width: 100%;
    min-width: 300px;
    border: 2px solid $secondaryColor;
    padding: 20px;
    @include centerElementByFlex(column, center, unset);
    border-radius: 8px;
    margin: 20px 10px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 29px;
      text-decoration-line: underline;
      text-align: center;
      color: $secondaryColor;
      margin-bottom: 20px;
    }
    .send-email-button-wrapper {
      @include centerElementByFlex(row, center, center);
      .send-email-button {
        border: 1px solid green;
        margin: 10px auto;
      }
    }
  }
}

.change-password-wrapper {
  @include centerElementByFlex(row, center, center);
  .token-fail-message-wrapper {
    @include centerElementByFlex(column, center, center);
    .error {
      color: white;
      font-size: 20px;
      margin: 20px 0px;
    }
  }
  .container {
    max-width: 600px;
    width: 100%;
    min-width: 300px;
    border: 2px solid $secondaryColor;
    padding: 20px;
    @include centerElementByFlex(column, center, unset);
    border-radius: 8px;
    margin: 20px 0px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 29px;
      text-decoration-line: underline;
      text-align: center;
      color: $secondaryColor;
      margin-bottom: 20px;
    }
    .input {
      margin: 10px 0px;
    }
    .save-button-wrapper {
      @include centerElementByFlex(row, center, center);
      .save-button {
        border: 1px solid green;
        margin: 10px auto;
        width: 100px;
      }
    }
  }
}

.team-upper-section {
  background: $primaryColor;
  padding: 20px;
  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    text-decoration-line: underline;
    text-align: center;
    color: $secondaryColor;
  }
  .team-menu {
    margin-top: 30px;
    @include centerElementByFlex(row, space-evenly, center);
    .team-card {
      @include centerElementByFlex(column, center, center);
      .team-icon {
        font-size: 50px;
        color: $secondaryColor;
      }
      .team-header {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        color: $secondaryColor;
        margin: 10px 0px;
      }
      .team-paragraph {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        color: white;
      }
    }
  }
}

.team-member-exist-user-btn {
  @include centerElementByFlex(row, center, center);
  margin: 30px;
}

.team-form-para {
  color: #ffff;
  .terms-and-conditon-heading {
    color: $secondaryColor;
    text-decoration-line: underline;
  }
}

.team-signup-form-wrapper {
  @include centerElementByFlex(row, center, center);
}

.border-line {
  border-bottom: 3px solid $secondaryColor;
  margin: 10px 0px;
}

//.................................. TeamMember signup ..................................
.payment-btn-wrapper {
  @include centerElementByFlex(row, center, center);
  margin-top: 40px;
  .payment-btn {
    padding: 10px 50px;
    font-size: 24px;
  }
}

.token-btn-wrapper {
  @include centerElementByFlex(row, center, center);
  .token-img-wrapper {
    height: 400px;
    width: 50%;
    @include centerElementByFlex(row, center, center);
  }
  .token-img {
    height: 95%;
  }
}

.token-second-wrapper {
  height: 400px;
  width: 50%;
  @include centerElementByFlex(row, space-evenly, center);
  .token-heading-wrapper {
    border: 2px solid $secondaryColor;
    border-radius: 10px;
    width: 95%;
    margin: 10px 0px;
    .token-heading {
      color: $secondaryColor;
      text-align: center;
    }
  }
  .token-buttons-wrapper {
    height: auto;
    width: 80%;
    .token-btn {
      width: 31%;
      margin: 10px 1px;
    }
    .custom-btn {
      width: 95%;
      margin: 10px 1px;
    }
  }
}

@media screen and (max-width: 1460px) {
  .custom-token-button {
    padding: 10px 20px;
    font-size: 22px;
    margin-top: 10px;
  }
  .toekn-btn {
    width: 40%;
  }
}

@media screen and (max-width: 1000px) {
  .admin-signup-wrapper {
    .upper-section {
      padding: 20px;
      .heading {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        text-decoration-line: underline;
        text-align: center;
        color: $secondaryColor;
      }
      .para {
        font-style: normal;
        font-weight: 500;
        max-width: 600px;
        margin: auto;
        font-size: 20px;
        text-align: center;
        color: white;
      }
      .menu {
        margin-top: 30px;
        @include centerElementByFlex(row, space-evenly, center);
        .card {
          @include centerElementByFlex(column, center, center);
          .icon {
            font-size: 40px;
            color: $secondaryColor;
          }
          .header {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            color: $secondaryColor;
            margin: 10px 0px;
          }
          .paragraph {
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            text-align: center;
            color: white;
          }
        }
      }
    }
    .lower-section {
      padding: 20px;
      .existing-button {
        @include centerElementByFlex(row, center, center);
      }
      .condition-list {
        color: white;
        margin: 20px 0px;
        display: flex;
        .para {
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          display: inline;
          .link {
            color: $secondaryColor;
            text-decoration: underline;
            font-weight: 500;
          }
        }
      }
      .submit-button {
        width: 300px;
        height: 50px;
        font-weight: 700;
        font-size: 20px;
        margin: 0px;
        @include centerElementByFlex(row, center, center);
        margin-top: 20px;
      }
    }
  }
  .participant-signup-wrapper {
    .upper-section {
      background: $primaryColor;
      padding: 20px;
      .heading {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        text-decoration-line: underline;
        text-align: center;
        color: $secondaryColor;
      }
      .menu {
        margin-top: 30px;
        @include centerElementByFlex(row, space-evenly, center);
        .card {
          @include centerElementByFlex(column, center, center);
          .icon {
            font-size: 40px;
            color: $secondaryColor;
          }
          .header {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            color: $secondaryColor;
            margin: 10px 0px;
          }
          .paragraph {
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            text-align: center;
            color: white;
          }
        }
      }
    }
    .lower-section {
      padding: 20px;
      .existing-button {
        @include centerElementByFlex(row, center, center);
      }
      .condition-list {
        color: white;
        margin: 20px 0px;
        display: flex;
        .para {
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          display: inline;
          .link {
            color: $secondaryColor;
            text-decoration: underline;
            font-weight: 500;
          }
        }
      }
      .submit-button {
        width: 300px;
        height: 50px;
        font-weight: 700;
        font-size: 20px;
        margin: 0px;
        @include centerElementByFlex(row, center, center);
        margin-top: 20px;
      }
    }
  }
  // ================== Team signup media querry =====================//
  .token-btn-wrapper {
    @include centerElementByFlex(column, center, center);
    .token-img-wrapper {
      display: none;
    }
    .token-second-wrapper {
      height: auto;
      width: 100%;
      @include centerElementByFlex(row, space-evenly, center);
      .token-heading-wrapper {
        border: 2px solid $secondaryColor;
        border-radius: 10px;
        width: 95%;
        margin: 10px 0px;
        .token-heading {
          color: $secondaryColor;
          text-align: center;
        }
      }
      .token-buttons-wrapper {
        height: auto;
        width: 80%;
        .token-btn {
          width: 31%;
          margin: 10px 1px;
        }
        .custom-btn {
          width: 95%;
          margin: 10px 1px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .admin-signup-wrapper {
    .upper-section {
      padding: 20px;
      .heading {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        text-decoration-line: underline;
        text-align: center;
        color: $secondaryColor;
      }
      .para {
        font-style: normal;
        font-weight: 500;
        max-width: 500px;
        margin: auto;
        font-size: 16px;
        text-align: center;
        color: white;
      }
      .menu {
        margin-top: 30px;
        @include centerElementByFlex(column, center, center);
        .card {
          @include centerElementByFlex(column, center, center);
          margin: 20px 0px;
          .icon {
            font-size: 40px;
            color: $secondaryColor;
          }
          .header {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            color: $secondaryColor;
            margin: 10px 0px;
          }
          .paragraph {
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            text-align: center;
            color: white;
          }
        }
      }
    }
    .lower-section {
      padding: 0px;
      .existing-button {
        @include centerElementByFlex(row, center, center);
      }
      .signup-form {
        padding: 20px 0px;
      }
      .condition-list {
        color: white;
        margin: 20px 0px;
        display: flex;
        .para {
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          display: inline;
          .link {
            color: $secondaryColor;
            text-decoration: underline;
            font-weight: 500;
          }
        }
      }
      .submit-button {
        width: 100%;
        height: 50px;
        font-weight: 700;
        font-size: 20px;
        margin: 0px;
        @include centerElementByFlex(row, center, center);
        margin-top: 20px;
      }
    }
  }
  .participant-signup-wrapper {
    .upper-section {
      background: $primaryColor;
      padding: 20px;
      .heading {
        font-style: normal;
        font-weight: 700;
        font-size: 28px;
        text-decoration-line: underline;
        text-align: center;
        color: $secondaryColor;
      }
      .menu {
        margin-top: 30px;
        @include centerElementByFlex(column, center, center);
        .card {
          @include centerElementByFlex(column, center, center);
          margin: 20px 0px;
          .icon {
            font-size: 40px;
            color: $secondaryColor;
          }
          .header {
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            text-align: center;
            color: $secondaryColor;
            margin: 10px 0px;
          }
          .paragraph {
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            text-align: center;
            color: white;
          }
        }
      }
    }
    .lower-section {
      padding: 0px;

      .existing-button {
        @include centerElementByFlex(row, center, center);
      }
      .signup-form {
        padding: 20px 0px;
      }
      .condition-list {
        color: white;
        margin: 20px 0px;
        display: flex;
        .para {
          font-style: normal;
          font-weight: 300;
          font-size: 12px;
          display: inline;
          .link {
            color: $secondaryColor;
            text-decoration: underline;
            font-weight: 500;
          }
        }
      }
      .submit-button {
        width: 100%;
        height: 50px;
        font-weight: 700;
        font-size: 20px;
        margin: 0px;
        @include centerElementByFlex(row, center, center);
        margin-top: 20px;
      }
    }
  }

  // =================Team signup MediaQuerry ================//
  .token-second-wrapper {
    height: auto;
    width: 100%;
    @include centerElementByFlex(row, space-evenly, center);
    .token-heading-wrapper {
      border: 2px solid $secondaryColor;
      border-radius: 10px;
      width: 95%;
      margin: 10px 0px;
      .token-heading {
        color: $secondaryColor;
        text-align: center;
      }
    }
    .token-buttons-wrapper {
      height: auto;
      width: 80%;
      .token-btn {
        width: 31%;
        margin: 10px 1px;
      }
      .custom-btn {
        width: 95%;
        margin: 10px 1px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .recaptcha-container {
    width: 100%;
    margin-left: -5px;
  }
  .confirm-organization-dialog-wrapper {
    .actions-wrapper {
      @include centerElementByFlex(column, center, center);
      .button {
        margin: 5px 0px;
      }
    }
  }
  // =================Team signup MediaQuerry ================//
  .admin-signup-wrapper {
    .upper-section {
      padding: 20px;
      .heading {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        text-decoration-line: underline;
        text-align: center;
        color: $secondaryColor;
      }
      .para {
        font-style: normal;
        font-weight: 500;
        max-width: 400px;
        margin: auto;
        font-size: 14px;
        text-align: center;
        color: white;
      }
    }
  }

  .token-btn-wrapper {
    @include centerElementByFlex(column, center, center);
    .token-img-wrapper {
      display: none;
    }
  }
}

.payment-form-wrapper {
  form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1),
      0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }
  .amount-visual {
    text-align: center;
  }

  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }

  #payment-element {
    margin-bottom: 24px;
  }

  /* Buttons and links */
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }

  button:hover {
    filter: contrast(115%);
  }

  button:disabled {
    opacity: 0.5;
    cursor: default;
  }

  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }

  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }

  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }

  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
      min-width: initial;
    }
  }
}

.token-selector-modal {
  width: 300px;
  .token-heading-wrapper {
    border: 2px solid $secondaryColor;
    border-radius: 10px;
    width: 95%;
    margin: 10px 0px;
    .token-heading {
      color: $secondaryColor;
      text-align: center;
    }
  }
  .token-buttons-wrapper {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn-group {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      .btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0px 5px;
      }
      p {
        margin-top: 2px;
        font-size: 12px;
      }
    }
    .token-btn {
      width: 70px;
      margin: 0px 2px;
    }
    .custom-btn {
      width: 95%;
      margin: 10px 1px;
    }
    .custom-input {
      width: 95%;
    }
  }
}

@media screen and (max-width: 600px) {
  .token-selector-modal {
    width: 100%;
  }
}
