@import "../../../_variables";
.team-dashboard-main-wrapper {
  width: 100%;
  padding: 10px 10px;
  .games-info {
    color: white;
    margin: 10px;
    text-align: center;
  }
  .games-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .games {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    max-width: 1200px;
  }
  .games-title {
    color: $secondaryColor;
    text-decoration-line: underline;
    @include centerElementByFlex(row, center, center);
    font-size: 40px;
    font-weight: 800;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .table {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    margin-top: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .team-dashboard-main-wrapper {
    .games-title {
      font-size: 24px;
    }
  }
}
.dashboard-title {
  color: $secondaryColor;
  text-decoration-line: underline;
  @include centerElementByFlex(row, center, center);
  font-size: 40px;
}
.team-dashboard-content-wrapper {
  @include centerElementByFlex(row, start, start);
  .content-first-wrapper,
  .content-second-wrapper {
    width: 50%;
    .content-title {
      color: $secondaryColor;
      text-decoration-line: underline;
      margin-top: 20px;
    }
    .content-heading {
      color: $secondaryColor;
      font-size: 20px;
      font-weight: 400;
      margin-top: 10px;
    }
    .content-para {
      font-size: medium;
      color: #fff;
      font-weight: 300;
      margin-top: 10px;
    }
  }
}

.dashboard-img-wrapper {
  @include centerElementByFlex(row, space-evenly, center);
  margin-top: 20px;
  .sponsor-Img {
    width: 100%;
    margin: 10px;
  }
  .complete-btn-img-wrapper {
    display: none;
  }
}

.Purchase-token-btn {
  @include centerElementByFlex(row, center, center);
  margin: 30px 0px;
  .purchase-btn {
    font-size: 22px;
  }
}
.badge-title {
  color: $secondaryColor;
  text-decoration-line: underline;
  @include centerElementByFlex(row, center, center);
  font-size: 50px;
}
.badge-para {
  color: $secondaryColor;
  text-decoration-line: underline;
}
.badge-img-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  flex-wrap: wrap;
  margin: auto;
}
.badges-img-parent {
  margin: 10px;
}
.sponsor-img-wrapper {
  @include centerElementByFlex(row, space-evenly, center);
  flex-wrap: wrap;
}
.donor-main-wrapper {
  @include centerElementByFlex(row, space-evenly, center);
  margin-top: 30px;
}
.donors-box-wrapper {
  width: 60%;
  padding: 50px;
  background-color: #878787;
}
.donor-line {
  border-bottom: 2px solid black;
  margin: 30px;
}
.complete-btn-main-wrapper {
  @include centerElementByFlex(row, none, none);
  .complete-btn-wrapper {
    height: auto;
    width: 100%;
    margin: 5px;

    .complete-btn {
      width: 100%;
      margin-top: 20px;
    }
    .complete-btn-secondary {
      width: 100%;
      margin-top: 20px;
      background: #b7b7b7 !important;
    }
  }
}

// =================== MediaQuerry ======================//

@media screen and (max-width: 1000px) {
  .dashboard-title {
    font-size: 24px;
  }
  .team-dashboard-content-wrapper {
    @include centerElementByFlex(row, start, start);
    .content-first-wrapper,
    .content-second-wrapper {
      width: 50%;
      padding: 10px;
      .content-title {
        font-size: 20px;
        margin-top: 10px;
      }
      .content-heading {
        font-size: 18px;
      }
      .content-para {
        font-size: 18px;
      }
    }
  }

  .team-dashboard-button-wrapper {
    @include centerElementByFlex(row, space-evenly, center);
    margin-top: 30px;
    .team-dashboard-btn {
      padding: 5px 50px;
      font-size: 18px;
    }
    .team-dashboard-btn1 {
      padding: 5px 50px;
      font-size: 18px;
    }
  }
  .badge-img-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    margin: auto;
  }
  .badges-img-parent {
    margin: 10px;
  }
  .sponsor-img-wrapper {
    @include centerElementByFlex(row, space-evenly, center);
    flex-wrap: wrap;
    width: 90%;
    margin: auto;
  }
  .dashboard-img-wrapper {
    @include centerElementByFlex(row, space-evenly, center);
    .sponsor-Img {
      height: 120px;
      width: 100%;
      margin: 10px;
    }
  }
  .complete-btn-main-wrapper {
    @include centerElementByFlex(row, none, none);
    .complete-btn-wrapper {
      height: auto;
      width: 80%;
      margin: 5px;

      .complete-btn {
        width: 100%;
        margin-top: 20px;
      }
      .complete-btn-secondary {
        width: 100%;
        margin-top: 20px;
        background: #b7b7b7 !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .badge-imge {
    height: 30vh;
  }
  .donor-img {
    height: 30vh;
  }
  .badge-title {
    font-size: 30px;
  }
  .team-dashboard-content-wrapper {
    @include centerElementByFlex(column, none, none);
    .content-first-wrapper,
    .content-second-wrapper {
      width: 100%;

      .content-title {
        font-size: 20px;
        margin-top: 10px;
      }
      .content-heading {
        font-size: 18px;
      }
      .content-para {
        font-size: 18px;
      }
    }
  }
  .team-dashboard-button-wrapper {
    @include centerElementByFlex(row, space-evenly, center);
    margin-top: 30px;
    .team-dashboard-btn {
      padding: 5px 50px;
      font-size: 18px;
    }
    .team-dashboard-btn1 {
      padding: 5px 50px;
      font-size: 18px;
    }
  }
  .donors-box-wrapper {
    padding: 10px;
    width: 80%;
  }
  .dashboard-img-wrapper {
    @include centerElementByFlex(column, space-evenly, center);
    .sponsor-Img {
      height: 300px;
      width: 100%;
      margin: 10px;
    }
    .complete-btn-img-wrapper {
      height: auto;
      width: 100%;
      margin: 5px;
      display: block;
      .complete-btn {
        width: 100%;
        margin-top: 20px;
      }
      .complete-btn-secondary {
        width: 100%;
        margin-top: 20px;
        background: #b7b7b7 !important;
      }
    }
  }
  .complete-btn-main-wrapper {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .team-dashboard-main-wrapper {
    width: 100%;
    padding: 10px 10px;
  }
  .badge-imge {
    height: 20vh;
  }
  .donor-img {
    height: 20vh;
  }
  .badge-title {
    font-size: 24px;
  }
  .team-dashboard-button-wrapper {
    @include centerElementByFlex(row, space-evenly, center);
    margin-top: 15px;
    .team-dashboard-btn {
      padding: 5px 20px;
      font-size: 10px;
    }
    .team-dashboard-btn1 {
      padding: 5px 20px;
      font-size: 10px;
    }
  }
  .donors-box-wrapper {
    padding: 5px;
    width: 100%;
  }
  .Purchase-token-btn {
    @include centerElementByFlex(row, center, center);
    margin: 10px 0px;
    .purchase-btn {
      font-size: 10px;
    }
  }
  .dashboard-img-wrapper {
    @include centerElementByFlex(column, space-evenly, center);
    .sponsor-Img {
      height: 200px;
      width: 100%;
      margin: 10px;
    }
  }
}
